import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from "axios";
import VueI18n from "vue-i18n";
import translations from "./assets/translations.js"; // import the translation obj
import vuetify from "./plugins/vuetify";
import VuetifyDialog from "vuetify-dialog";
import "vuetify-dialog/dist/vuetify-dialog.css";
import "vuejs-dialog/dist/vuejs-dialog.min.css";
import * as VueGoogleMaps from "vue2-google-maps";

import VueMarkdownEditor from "@kangc/v-md-editor";
import "@kangc/v-md-editor/lib/style/base-editor.css";
import vuepressTheme from "@kangc/v-md-editor/lib/theme/vuepress.js";
import "@kangc/v-md-editor/lib/theme/style/vuepress.css";

// Prism
import Prism from "prismjs";

import appData from "../package.json";
import "./assets/scss/custom.scss"; /* import custom scss */

axios.defaults.withCredentials = false; // this is needed because by default cookies are not passed by Axios.
axios.defaults.baseURL = process.env.VUE_APP_API_BASE;

/* define this variable in the prototype. 
Get it via: this.$updatedMessage */
Vue.prototype.$appVersion = appData.version;
Vue.prototype.$updatedMessage = "Der Datensatz wurde erfolgreich geupdated!";
Vue.prototype.$deletedMessage = "Der Datensatz wurde erfolgreich gelöscht!";
Vue.prototype.$createdMessage =
  "Ein neuer Datensatz wurde erfolgreich angelegt!";
Vue.prototype.$frontendUrl = process.env.VUE_APP_FRONTEND_URL; // the frontend (node.js hosting)
Vue.prototype.$adminUrl = process.env.VUE_APP_ADMIN_URL; // the URL zu mitfitAdmin
Vue.prototype.$mediapoolUrl = process.env.VUE_APP_MEDIAPOOL_URL; // public URL for media in mediapool
Vue.prototype.$obaUrl = process.env.VUE_APP_OBA_URL; // public URL for oba
Vue.prototype.$scoutingUrl = process.env.VUE_APP_SCOUTING_URL; // public URL for scouting

Vue.config.productionTip = false;

Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyBj3D0Q47cGrRv2yj_hfKFJzeNduzxvPC8",
    libraries: "places", // This is required if you use the Autocomplete plugin
    // OR: libraries: 'places,drawing'
    // OR: libraries: 'places,drawing,visualization'
    // (as you require)
    // If you want to set the version, you can do so:
    // v: '3.26',
  },
  installComponents: true,
});

Vue.use(VuetifyDialog, {
  context: {
    vuetify,
  },
  confirm: {
    actions: {
      false: {
        text: "NEIN",
        color: "green",
      },
      true: {
        text: "JA",
        color: "red",
      },
    },
    icon: false, // to disable icon just put false
    width: 500,
  },
  warning: {},
  error: {},
  prompt: {},
});

Vue.use(VueI18n);
// must be called after vue.use
const i18n = new VueI18n({
  locale: "de",
  fallbackLocale: "de",
  messages: translations,
});

import enUS from "@kangc/v-md-editor/lib/lang/de-DE";
VueMarkdownEditor.lang.use("de-DE", enUS);

import createEmojiPlugin from "@kangc/v-md-editor/lib/plugins/emoji/index";
import "@kangc/v-md-editor/lib/plugins/emoji/emoji.css";
VueMarkdownEditor.use(createEmojiPlugin());

VueMarkdownEditor.use(vuepressTheme, {
  Prism,
});

Vue.use(VueMarkdownEditor);

new Vue({
  store,
  router,
  vuetify,
  i18n,
  data: {
    snackbarGlobal: {
      snackbar: false,
      snackbarText: "",
      snackbarColor: "primary",
      snackbarTimeout: 2000,
      errorMessage: "",
    },
  },
  render: (h) => h(App),
}).$mount("#app");

/* The data object in new Vue:
Hier definiere ich globale Variablen, die ich von überall aus mutieren kann.
Das geht dann so: 
$root.snackbarGlobal.snackbar = true;
$root.snackbarGlobal.snackbarText = 'Eine Fehler ist aufgetreten';
*/
