import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

function envColor() {

  const env = process.env.VUE_APP_ENVIROMENT;

  if (env === "devel") {
    return "#E91E63";
  } else if (env === "test") {
    return "#FF9800";
  } else if (env === "preprod") {
    return "#009688";
  } else {
    return "#8BC34A";
  }
}


export default new Vuetify({

  theme: {
    dark: false,
    themes: {
      light: {
        primary: envColor(),
        secondary: '#4B5047',
        accent: '#8c9eff',
        error: '#b71c1c',
        facebook: '#2D88FF',
        lightgray: '#eeeeee',
        white: '#ffffff',
      },
    }
  }

});