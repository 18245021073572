<template>
  <div>

      <v-btn color="primary" elevation="0" @click="toSettings()">{{username}}
        <v-icon right dark>mdi-account-cog</v-icon>
      </v-btn>

      <v-btn color="primary" elevation="0" @click="logout()">Abmelden
        <v-icon right dark>mdi-logout</v-icon>
      </v-btn>

  </div>
</template>


<script>
import {
  mapActions
} from "vuex"; // import actions from vuex (eg. store/modules/auth.js)

export default {
  props: ['username'],

  methods : {

    ...mapActions({ // spread actions in to component methods (LogIn is a method in auth.js actions)
      LogOut: 'auth/LogOut'
    }),

    logout() {

      this.LogOut()
      this.$root.snackbarGlobal.snackbar = true;
      this.$root.snackbarGlobal.snackbarText = 'Du hast Dich erfolgreich ausgeloggt!';
      this.$root.snackbarGlobal.errorMessage = '<br/>melde Dich erneut an, wenn Du fortfahren möchtest.';
      this.$root.snackbarGlobal.snackbarColor = 'primary'
      this.$root.snackbarGlobal.snackbarTimeout = 5000

    },

    toSettings() {
      this.$router.push("/settings").catch(()=>{});
    }

  }
}
</script>