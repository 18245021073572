<template>
  <v-card :loading="loading" class="mx-auto">
    <v-card-title>Lorem Ipsum</v-card-title>

    <v-sheet
      class="v-sheet--offset mx-auto"
      color="primary"
      max-width="calc(100% - 32px)"
    >
      <v-sparkline
        :labels="labels"
        :value="value"
        color="white"
        line-width="2"
        padding="16"
      ></v-sparkline>
    </v-sheet>

    <v-card-text>
      Lorem ipsum dolor, sit amet consectetur adipisicing elit. Aliquid
      praesentium ducimus tempore accusantium aperiam, suscipit numquam fugit,
      doloribus doloremque soluta, magni nobis maxime? Labore ipsam, ad
      consequatur ullam deleniti amet.
    </v-card-text>

    <v-divider class="my-2"></v-divider>

    <v-card-actions>
      <v-btn color="primary" text>Hello</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  data: () => ({
    loading: false,
    labels: ["12am", "3am", "6am", "9am", "12pm", "3pm", "6pm", "9pm"],
    value: [200, 675, 410, 390, 310, 460, 250, 240],
  }),
};
</script>
