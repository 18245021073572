<template>
  <v-dialog v-model="dialog" scrollable width="600px" max-width="90%">
    <template v-slot:activator="{ on, attrs }">
      <v-badge
        :content="messagesNum"
        :value="messagesNum"
        color="green lighten-1"
        overlap
      >
        <v-btn
          fab
          dark
          :small="small"
          :x-small="xsmall"
          color="blue"
          elevation="0"
          v-bind="attrs"
          v-on="on"
        >
          <v-icon dark>mdi-chat-processing-outline</v-icon>
        </v-btn>
      </v-badge>
    </template>

    <v-card>
      <v-card-title class="text-h5 primary white--text"
        >Anmerkungen</v-card-title
      >

      <v-card-text class="primary darken-3 pt-4" style="height: 300px">
        <v-row>
          <v-col cols="12" v-for="message of messages" :key="message.id">
            <v-row v-if="message.receivers_id !== '0'">
              <v-col cols="4"></v-col>

              <v-col cols="8">
                <v-card elevation="2" class="primary lighten-3">
                  <v-card-text>
                    <v-row no-gutters>
                      <v-col cols="12">
                        <span class="caption"
                          >{{ message.name }} am
                          {{ formatDate(message.created) }}</span
                        >
                      </v-col>

                      <v-col cols="12">{{ message.message }}</v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>

            <v-row v-else-if="!message.message">
              <v-col cols="4"></v-col>

              <v-col cols="8">
                <v-card elevation="2" class="primary lighten-3">
                  <v-card-text>
                    <v-row no-gutters>
                      <v-col cols="12">Nachricht wird verarbeitet ...</v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>

            <v-row v-else>
              <v-col cols="8">
                <v-card elevation="2">
                  <v-card-text>
                    <v-row no-gutters>
                      <v-col cols="12">
                        <span class="caption"
                          >{{ message.name }} am
                          {{ formatDate(message.created) }}</span
                        >
                      </v-col>

                      <v-col cols="12">{{ message.message }}</v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>

              <v-col cols="4"></v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-form
          ref="form"
          v-model="isFormValid"
          v-on:submit.prevent="sendMessage()"
          style="width: 100%"
        >
          <v-row justify="center" align="center" no-gutters>
            <v-col cols="11">
              <v-text-field
                label="Nachricht"
                v-model="message"
                :rules="[rules.required]"
                :disabled="fetchinData"
              ></v-text-field>
            </v-col>

            <v-col cols="1">
              <v-btn
                v-if="isFormValid"
                class="mx-2"
                fab
                dark
                small
                elevation="0"
                color="green"
                @click="sendMessage"
                :loading="fetchinData"
              >
                <v-icon dark>mdi-send</v-icon>
              </v-btn>

              <v-btn v-else class="mx-2" fab small elevation="0" disabled>
                <v-icon dark>mdi-send</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import apiRequest from "@/mixins/apiRequest"; // import mixin (for api requests)

export default {
  props: ["slotNo", "planId", "buttonSize"],

  data() {
    return {
      fetchinData: false,
      isFormValid: false,
      dialog: false,
      data: [],
      messages: [],
      message: "",
      chatId: null,
      messagesNum: 0,
      refreshIntervalId: null,
      currentCompanyId: this.$store.getters["auth/current_sid"],
      small: true,
      xsmall: false,
      rules: {
        required: (value) => !!value || "Pflichtfeld",
      },
    };
  },

  created: function () {
    if (this.buttonSize === "x-small") {
      this.small = false;
      this.xsmall = true;
    }
    this.getData();
  },

  /* 
    this.$el.scrollTop = 0
    */

  watch: {
    // whenever question changes, this function will run
    dialog: function () {
      const that = this;

      if (this.dialog) {
        setTimeout(function () {
          let element = document.getElementsByClassName("v-dialog--active")[0];
          let scroller = element.getElementsByClassName("v-card__text")[0];
          let height = scroller.getElementsByClassName("row")[0].offsetHeight;
          scroller.scrollTo(0, height);
        }, 500);

        this.refreshIntervalId = setInterval(function () {
          that.getData();
        }, 10000);
      } else {
        clearInterval(this.refreshIntervalId);
      }
    },
  },

  methods: {
    async getData() {
      this.fetchinData = true;
      let response = await this.getRequest(
        "plans/" + this.planId + "/messagesByMonth/" + this.slotNo
      ); // await data from mixin
      this.fetchinData = false;
      this.data = response.data.data;
      this.messages = this.data.messages;
      this.messagesNum = this.data.count;
    },

    addZero(i) {
      if (i < 10) {
        i = "0" + i;
      }
      return i;
    },

    formatDate(date) {
      date = date.replace(/-/g, "/"); // Fix: Safari Bug

      const dt = new Date(date);
      const d = this.addZero(dt.getDate()); //Month from 0 to 11
      const m = this.addZero(dt.getMonth() + 1); //Month from 0 to 11
      const y = dt.getFullYear();
      const hours = this.addZero(dt.getHours());
      const minutes = this.addZero(dt.getMinutes());

      return d + "." + m + "." + y + ", " + hours + ":" + minutes + " Uhr";
    },

    async sendMessage() {
      if (this.isFormValid) {
        const formData = new FormData();
        formData.append("message", this.message);

        this.fetchinData = true;
        let response = await this.postRequest(
          "plans/" + this.planId + "/messagesByMonth/" + this.slotNo,
          "",
          formData
        ); // await data from mixin

        if (response.status === 200) {
          /* CALL SNACKBAR */
          this.$root.snackbarGlobal.snackbar = true;
          this.$root.snackbarGlobal.snackbarText = "Nachricht wurde gesendet";
          this.$root.snackbarGlobal.snackbarColor = "primary";
          this.$root.snackbarGlobal.snackbarTimeout = 5000;

          const that = this;

          setTimeout(function () {
            that.message = "";
            that.$refs.form.resetValidation();

            that.fetchinData = false;
            that.getData();

            setTimeout(function () {
              let element =
                document.getElementsByClassName("v-dialog--active")[0];
              let scroller = element.getElementsByClassName("v-card__text")[0];
              let height =
                scroller.getElementsByClassName("row")[0].offsetHeight;
              scroller.scrollTo(0, height);
            }, 1000);
          }, 3000);
        }
      }
    },
  },

  /* all requests by mixin */
  mixins: [apiRequest],
};
</script>
