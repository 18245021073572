<template>
  <div>
    <v-app-bar color="primary" dense dark app>
      <div v-if="authenticated">
        <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      </div>

      <v-toolbar-title>
        mitfitManage {{ envInfo() }}
        <span v-if="maintenanceMode"><strong>[MAINTENANCE-MODE]</strong></span>
      </v-toolbar-title>

      <v-spacer></v-spacer>

      <div v-if="authenticated">
        <UserNavigation v-bind:username="user.firstname" />
      </div>
      <div v-else>
        <v-btn text to="/login">Login</v-btn>
      </div>
    </v-app-bar>

    <div v-if="authenticated">
      <v-navigation-drawer v-model="drawer" dark app>
        <v-list>
          <div v-for="route in router" :key="route.title">
            <div v-if="route.children && route.inNav">
              <!-- has children -->
              <v-list-group>
                <template v-slot:activator>
                  <v-list-item-icon v-if="route.icon && route.icon !== ''">
                    <v-icon>{{ route.icon }}</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title v-text="route.title"></v-list-item-title>
                  </v-list-item-content>
                </template>

                <v-list dense>
                  <div v-for="children in route.children" :key="children.title">
                    <div v-if="children.children && !route.hideChildren">
                      <!-- has children -->
                      <v-list-group v-if="children.inNav">
                        <template v-slot:activator>
                          <v-list-item-icon
                            v-if="children.icon && children.icon !== ''"
                          >
                            <v-icon small>{{ children.icon }}</v-icon>
                          </v-list-item-icon>
                          <v-list-item-content>
                            <v-list-item-title
                              v-text="children.title"
                            ></v-list-item-title>
                          </v-list-item-content>
                        </template>

                        <div
                          v-for="children2 in children.children"
                          :key="children2.title"
                        >
                          <v-list-item
                            v-if="children2.inNav"
                            :to="{ path: children2.path }"
                          >
                            <v-list-item-icon
                              v-if="children2.icon && children2.icon !== ''"
                            >
                              <v-icon x-small>{{ children2.icon }}</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                              <v-list-item-title
                                v-text="children2.title"
                              ></v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                        </div>
                      </v-list-group>
                    </div>

                    <div v-else>
                      <!-- no children -->
                      <v-list-item
                        v-if="children.inNav"
                        :to="{ path: children.path }"
                      >
                        <v-list-item-icon
                          v-if="children.icon && children.icon !== ''"
                        >
                          <v-icon small>{{ children.icon }}</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                          <v-list-item-title
                            v-text="children.title"
                          ></v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </div>
                  </div>
                </v-list>
              </v-list-group>
            </div>

            <div v-else>
              <!-- no children -->
              <div v-if="route.inNav">
                <v-list-item :to="{ path: route.path }">
                  <v-list-item-icon v-if="route.icon && route.icon !== ''">
                    <v-icon>{{ route.icon }}</v-icon>
                  </v-list-item-icon>

                  <v-list-item-content>
                    <v-list-item-title v-text="route.title"></v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </div>
            </div>
          </div>
        </v-list>

        <span class="caption white--text px-4" style="opacity: 0.2">
          App-Version: {{ this.$appVersion }}
        </span>
      </v-navigation-drawer>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import UserNavigation from "@/components/user/UserNavigation.vue";
export default {
  name: "AppBarNavigation",
  props: {
    maintenanceMode: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters({
      authenticated: "auth/authenticated",
      user: "auth/user",
    }),
  },
  components: {
    UserNavigation,
  },
  methods: {
    envInfo() {
      const env = process.env.VUE_APP_ENVIROMENT;

      if (env === "devel") {
        return " (DEVELOPMENT)";
      } else if (env === "test") {
        return " (TEST)";
      } else if (env === "preprod") {
        return " (PRE-PRODUCTION)";
      } else {
        return "";
      }
    },
  },
  data() {
    return {
      drawer: null,
      activeItem: null,
      router: this.$router.options.routes,
    };
  },
};
</script>
