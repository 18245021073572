<template>
  <v-layout
    v-if="!fetchingData"
    flex
    align-center
    align-content-center
    justify-center
    class="primary darken-3"
    style="height: 100vh"
  >
    <v-flex xs12 sm4>
      <v-card>
        <v-toolbar class="primary elevation-0">
          <v-toolbar-title class="white--text">Willkommen</v-toolbar-title>
        </v-toolbar>

        <v-card-text v-if="!systemRunning" class="pt-4">
          <h1 class="my-3">MAINTENANCE MODUS</h1>
          <p>
            Zur Zeit arbeiten wir, damit mitfit noch besser funktioniert. Du
            wirst dich aktuell nicht einloggen können. In Kürze sind wir aber
            wieder für dich da.
          </p>
        </v-card-text>

        <v-card-text class="pt-4">
          <v-form ref="form">
            <v-text-field
              label="Benutzername"
              v-model="form.username"
              required
            ></v-text-field>
            <v-text-field
              label="Passwort"
              v-model="form.password"
              :type="show1 ? 'text' : 'password'"
              min="8"
              counter
              required
            ></v-text-field>
            <v-layout justify-space-between>
              <v-btn @click="submit" class="primary white--text">Login</v-btn>
            </v-layout>
          </v-form>
        </v-card-text>
      </v-card>
      <span
        class="caption white--text text-center mt-4"
        style="display: block; opacity: 0.2; min-width: 100%"
      >
        App-Version: {{ this.$appVersion }}
      </span>
    </v-flex>
  </v-layout>
</template>

<script>
import apiRequest from "@/mixins/apiRequest";
import { mapActions } from "vuex"; // import actions from vuex (eg. store/modules/auth.js)

export default {
  name: "AuthForm",
  components: {},
  data() {
    return {
      form: {
        username: "",
        password: "",
      },
      show1: false,
      show2: true,
      show3: false,
      show4: false,
      password: "Password",
      rules: {
        required: (value) => !!value || "Required.",
        min: (v) => v.length >= 8 || "Min 8 characters",
        emailMatch: () => `The email and password you entered don't match`,
      },
      showError: false,
      fetchingData: false,
      systemRunning: false,
    };
  },

  mounted() {
    this.checkStatus();
  },
  methods: {
    ...mapActions({
      // spread actions in to component methods (LogIn is a method in auth.js actions)
      LogIn: "auth/LogIn",
    }),

    async checkStatus() {
      this.fetchingData = true;
      let response = await this.getRequest("public/status"); // await data from mixin
      if (response.status === 200) {
        const result = response.data.data;
        const mode = result.mode;
        const status = result.status;
        if (
          mode.manage === "regular" &&
          mode.api === "regular" &&
          status === 200
        ) {
          this.systemRunning = true;
        }
        this.fetchingData = false;
      } else {
        this.systemRunning = false;
        this.fetchingData = false;
      }
    },

    submit() {
      const User = new FormData();
      User.append("loginname", this.form.username);
      User.append("password", this.form.password);

      this.LogIn(User)
        .then(() => {
          this.showError = false;

          this.$root.snackbarGlobal.snackbar = true;
          this.$root.snackbarGlobal.snackbarText =
            "Schön, dass Du wieder da bist!";
          this.$root.snackbarGlobal.errorMessage = "";
          this.$root.snackbarGlobal.snackbarColor = "primary";
          this.$root.snackbarGlobal.snackbarTimeout = 5000;
        })
        .catch(() => {
          alert("Loginversuch gescheitert");
        });
    },
  },

  mixins: [apiRequest],
};
</script>
