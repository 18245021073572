<template>
  <v-container>
    <v-row>
      <v-col cols="12" sm="8" md="8">
        <CampaignsUpNext />
      </v-col>
      <v-col cols="12" sm="4" md="4">
        <SellingsCard />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import SellingsCard from "@/components/dashboard/SellingsCard.vue";
import CampaignsUpNext from "@/components/campaign_management/annual_marketing/CampaignsUpNext.vue";

export default {
  name: "Dashboard",
  components: {
    SellingsCard,
    CampaignsUpNext,
  },
  data: () => ({
    loading: false,
    selection: 1,
  }),
  methods: {
    //
  },
};
</script>
