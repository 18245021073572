import axios from "axios";

/* 
POWERED BY:
https://www.smashingmagazine.com/2020/10/authentication-in-vue-js/
https://www.youtube.com/watch?v=Vd1YwbHrY1Q&list=PLfdtiltiRHWF1jqLcNO_2jWJXj9RuSDvY&t=0s
*/

const state = {
  token: null,
  user: null,
};

const getters = {
  /* Getters are functionalities to get the state. It can be used in multiple components to get the current state. 
  The isAuthenticatated function checks if the state.user is defined or null and returns true or false respectively. 
  StatePosts and StateUser return state.posts and state.user respectively value. */

  authenticated(state) {
    return state.token && state.user;
  },

  user(state) {
    return state.user;
  },

  token(state) {
    return state.token;
  },
};

const actions = {
  /* Actions are functions that are used to commit a mutation to change the state or can be used to dispatch i.e calls another action. 
  It can be called in different components or views and then commits mutations of our state; */

  async Register({ dispatch }, form) {
    await axios.post("register", form);
    let UserForm = new FormData();
    UserForm.append("loginname", form.username);
    UserForm.append("password", form.password);
    await dispatch("LogIn", UserForm);
  },

  async LogIn({ dispatch }, User) {
    let response = await axios.post("public/sessions", {
      loginname: User.get("loginname"),
      password: User.get("password"),
    });

    let credentials = {
      token: response.data.data.token,
      userId: response.data.data.userId,
    };

    dispatch("attempt", credentials);
  },

  async attempt({ commit }, credentials) {
    commit("SET_TOKEN", credentials.token);

    try {
      let response = await axios.get("users/" + credentials.userId, {
        headers: {
          Token: credentials.token,
        },
      });

      commit("SET_USER", response.data.data);
    } catch (e) {
      commit("SET_TOKEN", null);
      commit("SET_USER", null);
    }
  },

  async LogOut({ commit }) {
    let user = null;
    commit("LogOut", user);
  },
};

const mutations = {
  SET_TOKEN(state, token) {
    state.token = token;
  },

  SET_USER(state, data) {
    state.user = data;
  },

  LogOut(state) {
    // DELETE /sessions
    axios.delete("sessions", {
      headers: {
        Token: state.token,
      },
    });

    state.user = null;
    state.token = null;
  },
};
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
