<template>
  <span>
    <v-btn
      depressed
      fab
      :small="small"
      :x-small="xsmall"
      color="green white--text"
      class="ma-0"
      v-if="campaign.plan.isLocked === '1'"
      :disabled="isSetup()"
      @click="update('0')"
    >
      <v-icon dark>mdi-lock</v-icon>
    </v-btn>
    <v-btn
      depressed
      fab
      dark
      :small="small"
      :x-small="xsmall"
      color="red"
      class="ma-0"
      v-else
      @click="update('1')"
    >
      <v-icon dark>mdi-lock-open</v-icon>
    </v-btn>
  </span>
</template>

<script>
import apiRequest from "@/mixins/apiRequest";
import helpers from "@/mixins/helpers";
export default {
  name: "LockToggle",

  props: ["campaign", "buttonSize"],

  data() {
    return {
      preloader: false,
      small: true,
      xsmall: false,
    };
  },

  created: function () {
    if (this.buttonSize === "x-small") {
      this.small = false;
      this.xsmall = true;
    }
  },

  methods: {
    async update(to) {
      // {"month":{"id":"37","isLocked":1}}

      const json = {
        month: {
          id: this.campaign.plan.monthId,
          isLocked: to,
        },
      };

      const customContentType = "json";

      this.preloader = true;
      let response = await this.patchRequest(
        "plans/" + this.campaign.plan.planId,
        json,
        customContentType
      );
      this.preloader = false;

      if (response.status === 200) {
        this.campaign.plan.isLocked = to; // mutate

        let message = "Der Monat wurde erfolgreich gesperrt";
        if (to === "0") {
          message = "Der Monat wurde erfolgreich entsperrt";
        }

        this.$root.snackbarGlobal.snackbar = true;
        this.$root.snackbarGlobal.snackbarText = message;
        this.$root.snackbarGlobal.snackbarColor = "primary";
        this.$root.snackbarGlobal.snackbarTimeout = 5000;
      }
    },

    isSetup() {
      if (this.campaign.plan.isSetup === "1") {
        return true;
      } else if (this.campaign.generatedCampaigns_id) {
        return true;
      }
    },
  },

  mixins: [apiRequest, helpers],
};
</script>
