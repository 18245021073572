<template>
  <div>
    <v-row>
      <v-col cols="12">
        <v-card :loading="preloader" style="overflow: hidden">
          <v-toolbar color="primary" dark elevation="0">
            <v-toolbar-title>Jahresmarketing Ablaufplan</v-toolbar-title>
          </v-toolbar>
          <v-card-text style="overflow: auto">
            <table class="default-table" v-if="!preloader">
              <thead>
                <tr>
                  <th>Kampagne</th>
                  <th>Quartals-Video</th>
                  <th>Firma</th>
                  <th>Budget</th>
                  <th>Chat</th>
                  <th>Lock</th>
                  <th>Auto</th>
                  <th>Quality</th>
                  <th>Meta</th>
                </tr>
              </thead>

              <tbody>
                <template v-for="(item, index) in data">
                  <tr :key="index">
                    <td
                      colspan="10"
                      class="subtitle-1 font-weight-bold grey darken-3 white--text"
                    >
                      Startdatum {{ formatDate(index) }}
                    </td>
                  </tr>

                  <tr
                    v-for="campaign in item.campaigns"
                    :key="index + '-' + campaign.id + '-' + campaign.company.id"
                  >
                    <td
                      v-if="!campaign.generatedCampaigns_id"
                      :key="index + '-' + campaign.id + '-name'"
                    >
                      {{ campaign.name }}
                    </td>

                    <td v-else :key="index + '-' + campaign.id + '-name'">
                      <router-link
                        :to="`/campaign-management/campaigns/${campaign.generatedCampaigns_id}`"
                        >{{ campaign.name }}</router-link
                      >
                    </td>

                    <td :key="index + '-' + campaign.id + '-video'">
                      <span v-if="campaign.videoId" class="caption">
                        Video: {{ campaign.videoName }} (ID:
                        {{ campaign.videoId }})<br />
                        Branding: {{ campaign.brandingName }}
                      </span>
                    </td>

                    <td :key="index + '-' + campaign.id + '-company'">
                      {{ campaign.company.name }}
                    </td>
                    <td :key="index + '-' + campaign.id + '-budget'">
                      {{ campaign.budget }} EUR
                    </td>
                    <td :key="index + '-' + campaign.id + '-chat'">
                      <SlotChat
                        :slotNo="campaign.plan.monthId"
                        :planId="campaign.plan.planId"
                      />
                    </td>
                    <td>
                      <LockToggle :campaign="campaign" />
                    </td>
                    <td>
                      <AutomateAction
                        :campaign="campaign"
                        @automated="
                          getData();
                          automatedDialog = true;
                        "
                      />
                    </td>

                    <td>
                      <CampaignCheckToggle :campaign="campaign" />
                    </td>

                    <td>
                      <CampaignAdProviderSetupToggle :campaign="campaign" />
                    </td>
                  </tr>
                </template>
              </tbody>
            </table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog v-model="automatedDialog" width="500">
      <v-card>
        <v-toolbar dark color="green" elevation="0">
          <v-toolbar-title>Die Kampagne wurde automatisiert</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="automatedDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="mt-2">
          <p>
            Es wurde eine neue Bestellung ausgelöst und eine Kampagne erstellt.
          </p>
          <p>
            Bitte schaue als nächstes in die Kampagnen-Inbox und bearbeite die
            neue Kampagne.
          </p>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green" text @click="automatedDialog = false">
            Alles klar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import apiRequest from "@/mixins/apiRequest";
import helpers from "@/mixins/helpers";
import SlotChat from "@/components/companies/addons/annual_marketing/SlotChat.vue";
import LockToggle from "@/components/campaign_management/annual_marketing/LockToggle.vue";
/* import SetupToggle from "@/components/campaign_management/annual_marketing/SetupToggle.vue"; */
import AutomateAction from "@/components/campaign_management/annual_marketing/AutomateAction.vue";
import CampaignCheckToggle from "@/components/campaign_management/annual_marketing/CampaignCheckToggle.vue";
import CampaignAdProviderSetupToggle from "@/components/campaign_management/annual_marketing/CampaignAdProviderSetupToggle.vue";

export default {
  name: "CampaignsUpNext",

  components: {
    SlotChat,
    LockToggle,
    /* SetupToggle, */
    AutomateAction,
    CampaignCheckToggle,
    CampaignAdProviderSetupToggle,
  },

  data() {
    return {
      pageTitle: "Jahresmarketing - Übersicht",
      pageDescription: "Chronologischer Ablaufplan, die nächsten 4 Wochen",
      preloader: false,
      data: [],
      automatedDialog: false,
    };
  },

  created: function () {
    this.getData(); // call it immediatly
  },

  methods: {
    async getData() {
      this.preloader = true;
      let response = await this.getRequest(
        "plans/upcomingCampaigns?filter[weeks]=4"
      );
      if (response.status === 200) {
        this.data = response.data.data;
      }
      this.preloader = false;
    },

    async lock(plan, companyId) {
      console.log(plan);
      console.log(companyId);
    },
  },

  mixins: [apiRequest, helpers],
};
</script>
