<template>
  <span>
    <v-btn
      v-if="!generatedCampaigns_id"
      depressed
      fab
      :small="small"
      :x-small="xsmall"
      color="red white--text"
      :disabled="isLocked()"
      @click="startAutomation()"
    >
      <v-icon dark>mdi-arrow-decision-auto</v-icon>
    </v-btn>

    <v-btn
      v-else
      depressed
      fab
      :small="small"
      :x-small="xsmall"
      :disabled="true"
      color="green white--text"
    >
      <v-icon dark>mdi-check-circle-outline</v-icon>
    </v-btn>
  </span>
</template>

<script>
import apiRequest from "@/mixins/apiRequest";
import helpers from "@/mixins/helpers";
export default {
  name: "AutomateAction",

  props: ["campaign", "buttonSize"],

  data() {
    return {
      preloader: false,
      small: true,
      xsmall: false,
      dialog: false,
      generatedCampaigns_id: this.campaign.generatedCampaigns_id,
    };
  },

  created: function () {
    if (this.buttonSize === "x-small") {
      this.small = false;
      this.xsmall = true;
    }
  },

  methods: {
    async startAutomation() {
      const route = `/plans/${this.campaign.plan.planId}/month/${this.campaign.plan.monthId}/automate`;
      console.log(route);

      const userChoice = await this.$dialog.confirm({
        text: `<strong style="color:red;">Es wird eine Automatisierung gestartet.</strong> Es werden automatisch eine Bestellung und eine Kampagne erstellt. Möchtest du fortfahren?`,
        title: "Achtung!",
        waitForResult: true,
      });

      if (userChoice) {
        // do it
        this.preloader = true;
        let response = await this.postRequest(route, null, null);

        if (response.status === 200) {
          this.$root.snackbarGlobal.snackbar = true;
          this.$root.snackbarGlobal.snackbarText =
            "Die Automatisierung ist abgeschlossen!";
          this.$root.snackbarGlobal.snackbarColor = "primary";
          this.$root.snackbarGlobal.snackbarTimeout = 5000;
          this.$emit("automated", true); // emit update
        } else {
          this.$root.snackbarGlobal.snackbar = true;
          this.$root.snackbarGlobal.snackbarText =
            "Der Automatisierung konnte nicht durchgeführt werden!";
          this.$root.snackbarGlobal.snackbarColor = "error";
          this.$root.snackbarGlobal.snackbarTimeout = 5000;
        }
        this.preloader = false;
      }
    },

    isLocked() {
      if (this.campaign.plan.isLocked !== "1") {
        return true;
      } else if (this.campaign.generatedCampaigns_id) {
        return true;
      }
    },
  },

  mixins: [apiRequest, helpers],
};
</script>
