import apiRequest from "@/mixins/apiRequest";
export default {

  methods: {

    addZero(i) {
      if (i < 10) {
        i = "0" + i;
      }
      return i;
    },

    formatDate(date) {

      if (date && date !== null && date !== undefined && date !== "" && date !== "0000-00-00") {

        const dt = new Date(date);
        const d = this.addZero(dt.getDate()); // Month from 0 to 11
        const m = this.addZero(dt.getMonth() + 1); // Month from 0 to 11
        const y = dt.getFullYear();

        const niceDate = d + "." + m + "." + y;

        if (!isNaN(dt)) {
          return niceDate;
        } else {
          // Safari-Bugfix
          date = date.replace(/-/g,"/");
          return this.formatDateTime(date);
        }
      } else {
        return null;
      }

    },

    formatDateTime(date) {

      if (date && date !== null && date !== undefined && date !== "" && date !== "0000/00/00 00:00:00") {
        
        const dt = new Date(date);
        const d = this.addZero(dt.getDate()); //Month from 0 to 11
        const m = this.addZero(dt.getMonth() + 1); //Month from 0 to 11
        const y = dt.getFullYear();
        const h = this.addZero(dt.getHours());
        const i = this.addZero(dt.getMinutes());

        const niceDate = d + "." + m + "." + y + ", " + h + ":" + i + " Uhr";

        if (!isNaN(dt)) {

          return niceDate;

        } else {

          // Safari-Bugfix
          date = date.replace(/-/g,"/");
          return this.formatDateTime(date);

        }
      } else {
        return null;
      }
    },


    periodsTranslation(value) {

      if (value === 'once') {
        return "Einmalig";
      } else if (value === 'daily') {
        return "Täglich";
      } else if (value === 'weekly') {
        return "Wöchentlich";
      } else if (value === 'monthly') {
        return "Monatlich";
      } else if (value === 'quarterly') {
        return "Alle drei Monate";
      } else if (value === 'halfyearly') {
        return "Halbjährlich";
      } else if (value === 'yearly') {
        return "Jährlich";
      } else {
        return value;
      }

    },

    runtimeTranslation(value) {

      if (value === 'days') {
        return "Tage";
      } else if (value === 'weeks') {
        return "Wochen";
      } else if (value === 'months') {
        return "Monate";
      } else if (value === 'quarters') {
        return "Quartale";
      } else if (value === 'halfyears') {
        return "Halbjahre";
      } else if (value === 'years') {
        return "Jahre";
      } else {
        return value;
      }

    },

    idToTitle(id,referenzArray) {
      /* für select patcher
      Gespeichert werden ids
      Als Vorschau sollen natürlich Title verwendet werden.
      Hier brauche ich einen ReferenzArray */

      let title = null;

      if (Array.isArray(referenzArray)) {
        referenzArray.forEach((item) => {
          if (item.id === id) {
            title = item.title;
          }
        });
      }
      
      return title;
    },

    idToName(id,referenzArray) {
      /* für select patcher
      Gespeichert werden ids
      Als Vorschau sollen natürlich Namen verwendet werden.
      Hier brauche ich einen ReferenzArray */

      let name = null;

      if (Array.isArray(referenzArray)) {
        referenzArray.forEach((item) => {
          if (item.id === id) {
            name = item.name;
          }
        });
      }
      
      return name;
    },

    async getCompanyNameById(id) {

      /* company name */
      const response = await this.getRequest(
        "companies/" + id
      );

      if (response.status === 200) {
        return response.data.data[0].name;
      }
    },


  },
  mixins: [apiRequest],
};