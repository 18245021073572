<template>
  <span>
    <v-btn
      v-if="adProviderSetup === '1'"
      depressed
      fab
      :small="true"
      color="green white--text"
      class="ma-0"
      :disabled="isSetup()"
      @click="update('0')"
    >
      <v-icon dark>mdi-check-circle-outline</v-icon>
    </v-btn>
    <v-btn
      v-else
      depressed
      fab
      :small="true"
      color="green white--text"
      class="ma-0"
      :disabled="isSetup()"
      @click="update('1')"
    >
      <v-icon dark>mdi-checkbox-blank-circle-outline</v-icon>
    </v-btn>
  </span>
</template>

<script>
import apiRequest from "@/mixins/apiRequest";
import helpers from "@/mixins/helpers";
export default {
  name: "CampaignAdProviderSetupToggle",

  props: ["campaign"],

  data() {
    return {
      preloader: false,
      generatedCampaigns_id: this.campaign.generatedCampaigns_id,
      isApproved: this.campaign.generatedCampaignsIsApproved,
      isChecked: this.campaign.generatedCampaignsIsChecked,
      adProviderSetup: this.campaign.generatedCampaignsAdProviderSetup,
      xsmall: false,
    };
  },

  methods: {
    async update(to) {
      /* 
      PATCH /campaigns/{id}/setChecked
      PATCH /campaigns/{id}/unsetChecked
      PATCH /campaigns/{id}/setAdProviderSetup
      PATCH /campaigns/{id}/unsetAdProviderSetup
      */

      let url = `/campaigns/${this.generatedCampaigns_id}/setAdProviderSetup`;
      if (to === "0") {
        url = `/campaigns/${this.generatedCampaigns_id}/unsetAdProviderSetup`;
      }

      this.preloader = true;
      let response = await this.patchRequest(url, null, null);
      this.preloader = false;

      if (response.status === 200) {
        this.adProviderSetup = to; // mutate

        let message = "Das Meta-Setup wurde erfolgreich als geprüft markiert";
        if (to === "0") {
          message =
            "Das Meta-Setup wurde erfolgreich als nicht geprüft markiert";
        }

        this.$root.snackbarGlobal.snackbar = true;
        this.$root.snackbarGlobal.snackbarText = message;
        this.$root.snackbarGlobal.snackbarColor = "primary";
        this.$root.snackbarGlobal.snackbarTimeout = 5000;
      }
    },

    isSetup() {
      if (
        !this.generatedCampaigns_id ||
        this.generatedCampaigns_id === null ||
        this.generatedCampaigns_id === ""
      ) {
        return true;
      } else if (this.isApproved === "1") {
        return true;
      }
    },
  },

  mixins: [apiRequest, helpers],
};
</script>
