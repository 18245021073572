

const state = {
  history: [],
};

const getters = {
  history(state) {
    return state.history
  },
};

const mutations = {
  ADD_HISTORY(state, entry) {
    // add entry to history
    state.history.push(entry)
  },
  REMOVE_HISTORY(state, entry) {
    // remove entry from history
    state.history.splice(state.history.indexOf(entry), 1)
  },
  CLEAR_HISTORY(state) {
    // clear history
    state.history = []
  },
  REMOVE_LAST_ITEM(state) {
    // remove last item from history
    state.history.pop()
  }
};
export default {
  namespaced: true,
  state,
  getters,
  mutations,
};