import Vue from "vue";
import VueRouter from "vue-router";
import Dashboard from "../views/Dashboard.vue";
import VueCookies from "vue-cookies";
//import store from "../store";

Vue.use(VueRouter);
Vue.use(VueCookies);

const routes = [
  {
    path: "/",
    name: "dashboard",
    title: "Dashboard",
    icon: "mdi-monitor-dashboard",
    inNav: true,
    component: Dashboard,
    meta: {
      requiresAuth: true,
    },
  },

  {
    path: "/login",
    name: "Login",
    title: "Login",
    inNav: false,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/user/Login.vue"),
  },

  // =============================================================================
  //    Sales Routes
  // =============================================================================

  {
    path: "/sales",
    name: "sales",
    title: "Sales",
    icon: "mdi-cart",
    inNav: true,
    hideChildren: false,
    component: {
      render: (h) => h("router-view"),
    },

    children: [
      {
        path: "/sales/products",
        name: "sales.products",
        title: "Produkte",
        icon: "mdi-arrow-right",
        inNav: true,
        component: () => import("../views/sales/products/Products"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/sales/orders",
        name: "sales.orders",
        title: "Bestellungen",
        icon: "mdi-arrow-right",
        inNav: true,
        component: () => import("../views/sales/orders/Orders.vue"),
        meta: {
          requiresAuth: true,
        },
      },

      {
        path: "/sales/orders/:id",
        name: "sales.orders.:id",
        title: "Bestellungen Details",
        icon: "mdi-arrow-right",
        inNav: false,
        component: () => import("../views/sales/orders/Order"),
        meta: {
          requiresAuth: true,
        },
      },

      {
        path: "/sales/products/:id",
        name: "sales.products.:id",
        title: "Produkt Details",
        icon: "mdi-arrow-right",
        inNav: false,
        component: () => import("../views/sales/products/Product"),
        meta: {
          requiresAuth: true,
        },
      },

      {
        path: "/sales/pitches",
        name: "sales.pitches",
        title: "Pitches",
        icon: "mdi-arrow-right",
        inNav: true,
        component: () => import("../views/sales/pitches/Pitches"),
        meta: {
          requiresAuth: true,
        },
      },

      {
        path: "/sales/pitches/:id",
        name: "sales.pitches.:id",
        title: "Pitch Details",
        icon: "mdi-arrow-right",
        inNav: false,
        component: () => import("../views/sales/pitches/Pitch"),
        meta: {
          requiresAuth: true,
        },
      },
    ],
  },

  // =============================================================================
  //    Tech Routes
  // =============================================================================

  {
    path: "/tech",
    name: "tech",
    title: "Technik",
    icon: "mdi-progress-wrench",
    inNav: true,
    hideChildren: false,
    component: {
      render: (h) => h("router-view"),
    },

    children: [
      {
        path: "/tech/addons",
        name: "tech.addons",
        title: "Addons",
        icon: "mdi-puzzle",
        inNav: true,
        component: {
          render: (h) => h("router-view"),
        },
        meta: {
          requiresAuth: true,
        },

        children: [
          {
            path: "/tech/addons/info-mails-channels",
            name: "tech.addons.info-mails-channels",
            title: "Info-Mails-Channels",
            icon: "mdi-arrow-right",
            inNav: true,
            component: () =>
              import("../views/tech/info_mails_channels/index.vue"),
            meta: {
              requiresAuth: true,
            },
          },

          {
            path: "/tech/info-mails-channels/:id",
            name: "tech.addons.info-mails-channels.:id",
            title: "Info-Mails-Channel",
            icon: null,
            inNav: false,
            component: () =>
              import("../views/tech/info_mails_channels/_id.vue"),
            meta: {
              requiresAuth: true,
            },
          },
          {
            path: "/tech/addons/templates",
            title: "Jahrespläne",
            icon: "mdi-arrow-right",
            inNav: true,
            component: {
              render: (h) => h("router-view"),
            },
            meta: {
              requiresAuth: true,
            },
            children: [
              {
                path: "/tech/addons/templates",
                name: "tech.addons.yearplans.templates",
                title: "Jahrespläne",
                icon: "mdi-arrow-right",
                inNav: true,
                component: () =>
                  import(
                    "../views/tech/addons/yearplans/templates/Templates.vue"
                  ),
                meta: {
                  requiresAuth: true,
                },
              },
              {
                path: "/tech/addons/templates/:id",
                name: "tech.addons.yearplans.templates.:id",
                title: "Vorlage",
                icon: "mdi-arrow-right",
                inNav: false,
                component: () =>
                  import("../views/tech/addons/yearplans/templates/_id.vue"),
                meta: {
                  requiresAuth: true,
                },
              },
            ],
          },

          {
            path: "/tech/addons/shop/shops",
            title: "Shop",
            icon: "mdi-arrow-right",
            inNav: true,
            component: {
              render: (h) => h("router-view"),
            },
            meta: {
              requiresAuth: true,
            },
            children: [
              {
                path: "/tech/addons/shop/shops",
                name: "tech.addons.shop.shops",
                title: "Shops",
                icon: "mdi-arrow-right",
                inNav: true,
                component: () => import("../views/tech/addons/shop/Shops.vue"),
                meta: {
                  requiresAuth: true,
                },
              },
              {
                path: "/tech/addons/shop/products",
                name: "tech.addons.shop.products",
                title: "Produkte",
                icon: "mdi-arrow-right",
                inNav: true,
                component: () =>
                  import("../views/tech/addons/shop/products/Products.vue"),
                meta: {
                  requiresAuth: true,
                },
              },
            ],
          },

          {
            path: "/tech/addons/bonuscalendar",
            name: "tech.addons.bonuscalendar",
            title: "Bonuscalendar",
            icon: "mdi-arrow-right",
            inNav: true,
            component: () =>
              import("../views/tech/addons/bonus_calendar/BonusCalendar.vue"),
            meta: {
              requiresAuth: true,
            },
          },
        ],
      },
      {
        path: "/tech/pagetemplates",
        name: "tech.pagetemplates",
        title: "Landingpage-Templates",
        icon: "mdi-book-open-page-variant",
        inNav: true,
        hideChildren: false,
        component: () => import("../views/tech/pages/templates.vue"),
        meta: {
          requiresAuth: true,
        },
      },

      {
        path: "/tech/pages/:id",
        name: "tech.pages.:id",
        title: "Seitendetail",
        inNav: false,
        component: () => import("../views/tech/pages/_id.vue"),
        meta: {
          requiresAuth: true,
        },
      },

      {
        path: "/tech/page-plugins",
        name: "tech.page-plugins",
        title: "Page-Plugins",
        icon: "mdi-puzzle",
        inNav: true,
        component: {
          render: (h) => h("router-view"),
        },
        meta: {
          requiresAuth: true,
        },

        children: [
          {
            path: "/tech/page-plugins/oba",
            name: "tech.page-plugins.oba",
            title: "OBA",
            icon: "mdi-arrow-right",
            inNav: true,
            component: () => import("../views/tech/page-plugins/oba/Obas.vue"),
            meta: {
              requiresAuth: true,
            },
          },

          {
            path: "/tech/page-plugins/oba/:id",
            name: "tech.page-plugins.oba.:id",
            title: "OBA Detail",
            inNav: false,
            component: () => import("../views/tech/page-plugins/oba/Oba.vue"),
            meta: {
              requiresAuth: true,
            },
          },

          {
            path: "/tech/page-plugins/forms",
            name: "tech.page-plugins.forms",
            title: "Formulare",
            icon: "mdi-arrow-right",
            inNav: true,
            component: () =>
              import("../views/tech/page-plugins/forms/Forms.vue"),
            meta: {
              requiresAuth: true,
            },
          },

          {
            path: "/tech/page-plugins/forms/:id",
            name: "tech.page-plugins.forms.:id",
            title: "Form Detail",
            inNav: false,
            component: () =>
              import("../views/tech/page-plugins/forms/Form.vue"),
            meta: {
              requiresAuth: true,
            },
          },

          {
            path: "/tech/page-plugins/testimonials",
            name: "tech.page-plugins.testimonials",
            title: "Testimonials",
            icon: "mdi-arrow-right",
            inNav: true,
            component: () =>
              import(
                "../views/tech/page-plugins/testimonials/Testimonials.vue"
              ),
            meta: {
              requiresAuth: true,
            },
          },

          {
            path: "/tech/page-plugins/memory",
            name: "tech.pages.plugins.memory",
            title: "Memory",
            icon: "mdi-arrow-right",
            inNav: true,
            component: () =>
              import("../views/tech/page-plugins/memory/Memories.vue"),
            meta: {
              requiresAuth: true,
            },
          },

          {
            path: "/tech/page-plugins/memory/:id",
            name: "tech.page-plugins.memory.:id",
            title: "Memory Detail",
            inNav: false,
            component: () =>
              import("../views/tech/page-plugins/memory/Memory.vue"),
            meta: {
              requiresAuth: true,
            },
          },

          {
            path: "/tech/page-plugins/debugging_game",
            name: "tech.pages.plugins.debugging_game",
            title: "Fehlersuche",
            icon: "mdi-arrow-right",
            inNav: true,
            component: () =>
              import("../views/tech/page-plugins/debugging_game/Games.vue"),
            meta: {
              requiresAuth: true,
            },
          },

          {
            path: "/tech/page-plugins/debugging_game/:id",
            name: "tech.pages.plugins.debugging_game.:id",
            title: null,
            icon: null,
            inNav: false,
            component: () =>
              import("../views/tech/page-plugins/debugging_game/Game.vue"),
            meta: {
              requiresAuth: true,
            },
          },

          {
            path: "/tech/page-plugins/picture_puzzle",
            name: "tech.pages.plugins.picture_puzzle",
            title: "Bilderrätsel",
            icon: "mdi-arrow-right",
            inNav: true,
            component: () =>
              import("../views/tech/page-plugins/picture_puzzle/Games.vue"),
            meta: {
              requiresAuth: true,
            },
          },

          {
            path: "/tech/page-plugins/picture_puzzle/:id",
            name: "tech.pages.plugins.picture_puzzle.:id",
            title: null,
            icon: null,
            inNav: false,
            component: () =>
              import("../views/tech/page-plugins/picture_puzzle/Game.vue"),
            meta: {
              requiresAuth: true,
            },
          },
        ],
      },

      {
        path: "/tech/mediapool",
        name: "tech.pages.mediapool",
        title: "Medienpool",
        icon: "mdi-folder-multiple-image",
        inNav: true,
        component: () => import("../views/tech/mediapool/Mediapool.vue"),
        meta: {
          requiresAuth: true,
        },
      },

      {
        path: "/tech/mediapool/:id",
        name: "tech.pages.mediapool.:id",
        title: "Medienpool Category",
        icon: "mdi-folder-multiple-image",
        inNav: false,
        component: () => import("../views/tech/mediapool/Mediapool.vue"),
        meta: {
          requiresAuth: true,
        },
      },

      {
        path: "/tech/feedbacks",
        name: "tech.feedbacks",
        title: "Feedbacks",
        icon: "mdi-chat-processing",
        inNav: true,
        component: () => import("../views/tech/feedbacks/Feedbacks.vue"),
        meta: {
          requiresAuth: true,
        },
      },

      {
        path: "/tech/infomails",
        name: "tech.pages.infomails",
        title: "Info-Mails",
        icon: "mdi-email",
        inNav: true,
        component: () => import("../views/tech/info_mails/InfoMails.vue"),
        meta: {
          requiresAuth: true,
        },
      },

      {
        path: "/tech/facebook/connections",
        name: "tech.facebook.connections",
        title: "Facebook-App Verbindungen",
        icon: "mdi-facebook",
        inNav: true,
        component: () => import("../views/tech/facebook/connections.vue"),
        meta: {
          requiresAuth: true,
        },
      },

      {
        path: "/tech/scouting-boni",
        name: "tech.pages.scouting-boni",
        title: "Scouting-Boni",
        icon: "mdi-gift",
        inNav: true,
        component: () => import("../views/tech/scouting_boni/index.vue"),
        meta: {
          requiresAuth: true,
        },
      },

      {
        path: "/tech/scouting-boni/:id",
        name: "tech.pages.scouting-boni.:id",
        title: "Scouting-Bonus",
        icon: null,
        inNav: false,
        component: () => import("../views/tech/scouting_boni/_id.vue"),
        meta: {
          requiresAuth: true,
        },
      },

      {
        path: "/tech/ffnews",
        name: "tech.pages.ffnews",
        title: "News",
        icon: "mdi-newspaper",
        inNav: true,
        component: () => import("../views/tech/ffnews/index.vue"),
        meta: {
          requiresAuth: true,
        },
      },

      {
        path: "/tech/ffnews/:id",
        name: "tech.pages.ffnews.:id",
        title: "News",
        icon: null,
        inNav: false,
        component: () => import("../views/tech/ffnews/_id.vue"),
        meta: {
          requiresAuth: true,
        },
      },

      {
        path: "/tech/systemsettings",
        name: "tech.systemsettings",
        title: "System-Einstellungen",
        icon: "mdi-database-settings",
        inNav: true,
        component: {
          render: (h) => h("router-view"),
        },
        meta: {
          requiresAuth: true,
        },

        children: [
          {
            path: "/tech/systemsettings/branches",
            name: "tech.systemsettings.branches",
            title: "Branchen",
            icon: "mdi-arrow-right",
            inNav: true,
            component: () =>
              import("../views/tech/systemsettings/branches/index.vue"),
            meta: {
              requiresAuth: true,
            },
          },

          {
            path: "/tech/systemsettings/product-categories",
            name: "tech.systemsettings.product-categories",
            title: "Produkt-Kategorien",
            icon: "mdi-arrow-right",
            inNav: true,
            component: () =>
              import(
                "../views/tech/systemsettings/product-categories/index.vue"
              ),
            meta: {
              requiresAuth: true,
            },
          },
        ],
      },

      {
        path: "/tech/debugging",
        name: "tech.debugging",
        title: "Debugging",
        icon: "mdi-bug",
        inNav: true,
        component: {
          render: (h) => h("router-view"),
        },
        meta: {
          requiresAuth: true,
        },

        children: [
          {
            path: "/tech/debugging/logs",
            name: "tech.debugging.logs",
            title: "System-Logs",
            icon: "mdi-arrow-right",
            inNav: true,
            component: () =>
              import("../views/tech/debugging/logs/LogDebugger.vue"),
            meta: {
              requiresAuth: true,
            },
          },
          {
            path: "/tech/debugging/api/logs",
            name: "tech.debugging.api.logs",
            title: "API-Logs",
            icon: "mdi-arrow-right",
            inNav: true,
            component: () =>
              import("../views/tech/debugging/logs/ApiDebugger.vue"),
            meta: {
              requiresAuth: true,
            },
          },
          {
            path: "/tech/debugging/facebook/webhooks",
            name: "tech.debugging.facebook.webhooks",
            title: "Facebook Webhooks",
            icon: "mdi-arrow-right",
            inNav: true,
            component: () =>
              import("../views/tech/debugging/facebook/webhooks/Webhooks.vue"),
            meta: {
              requiresAuth: true,
            },
          },
        ],
      },
    ],
  },

  // =============================================================================
  //    Campaign Routes
  // =============================================================================

  {
    path: "/campaign-management",
    name: "campaign-management",
    title: "Kampagnen",
    icon: "mdi-rocket-launch",
    inNav: true,
    hideChildren: false,
    component: {
      render: (h) => h("router-view"),
    },

    children: [
      {
        path: "/campaign-management/campaigns/inbox",
        name: "campaign-management.campaigns.inbox",
        title: "Inbox",
        icon: "mdi-inbox-multiple",
        inNav: true,
        component: () =>
          import("../views/campaign_management/campaigns/Inbox.vue"),
        meta: {
          requiresAuth: true,
        },
      },

      {
        path: "/campaign-management/campaigns/waiting",
        name: "campaign-management.campaigns.waiting",
        title: "Wartend",
        icon: "mdi-clock-time-nine-outline",
        inNav: true,
        component: () =>
          import("../views/campaign_management/campaigns/Waiting.vue"),
        meta: {
          requiresAuth: true,
        },
      },

      {
        path: "/campaign-management/campaigns/running",
        name: "campaign-management.campaigns.running",
        title: "Aktiv",
        icon: "mdi-rocket-launch-outline",
        inNav: true,
        component: () =>
          import("../views/campaign_management/campaigns/Running.vue"),
        meta: {
          requiresAuth: true,
        },
      },

      {
        path: "/campaign-management/campaigns/running-special",
        name: "campaign-management.campaigns.running-special",
        title: "Aktiv (mitfitMEMBER Spezial)",
        icon: "mdi-rocket-launch-outline",
        inNav: true,
        component: () =>
          import(
            "../views/campaign_management/campaigns/RunningSpecialView.vue"
          ),
        meta: {
          requiresAuth: true,
        },
      },

      {
        path: "/campaign-management/campaigns/complete",
        name: "campaign-management.campaigns.complete",
        title: "Abgeschlossen",
        icon: "mdi-check-circle-outline",
        inNav: true,
        component: () =>
          import("../views/campaign_management/campaigns/Complete.vue"),
        meta: {
          requiresAuth: true,
        },
      },

      {
        path: "/campaign-management/campaigns/:id",
        name: "campaign-management.campaigns.:id",
        title: "Offen",
        icon: "mdi-arrow-right",
        inNav: false,
        component: () => import("../views/campaign_management/campaigns/_id/"),
        meta: {
          requiresAuth: true,
        },
      },

      {
        path: "/campaign-management/annual_marketing",
        name: "campaign-management.annual_marketing",
        title: "Jahresmarketing",
        icon: "mdi-calendar-month",
        inNav: true,
        component: () =>
          import("../views/campaign_management/annual_marketing/index.vue"),
        meta: {
          requiresAuth: true,
        },
      },
    ],

    meta: {
      requiresAuth: true,
    },
  },

  // =============================================================================
  //    CSM Routes
  // =============================================================================

  {
    path: "/csm",
    name: "csm",
    title: "CSM",
    icon: "mdi-cards-heart",
    inNav: true,
    hideChildren: false,
    component: {
      render: (h) => h("router-view"),
    },

    children: [
      {
        path: "/csm/company-manager",
        name: "csm.company-manager",
        title: "Company Manager",
        icon: "mdi-account-heart",
        inNav: true,
        hideChildren: true,
        component: () => import("../views/csm/company_manager/index.vue"),
        meta: {
          requiresAuth: true,
        },
      },

      {
        path: "/csm/company-manager/:id",
        redirect: "/csm/company-manager/:id/overview",
        name: "csm.company-manager.:id",
        inNav: false,
        hideChildren: true,
        component: {
          render: (h) => h("router-view"),
        },
        meta: {
          requiresAuth: true,
        },

        children: [
          {
            path: "overview",
            name: "csm.company-manager.:id.overview",
            title: "Übersicht",
            icon: "mdi-arrow-right",
            inNav: true,
            component: () => import("../views/csm/company_manager/_id/"),
            meta: {
              requiresAuth: true,
            },
          },
          {
            path: "basedata",
            name: "csm.company-manager.:id.basedata",
            title: "Basisdaten",
            icon: "mdi-arrow-right",
            inNav: false,
            component: () =>
              import("../views/csm/company_manager/_id/basedata/index.vue"),
            meta: {
              requiresAuth: true,
            },
          },

          {
            path: "addons",
            name: "csm.company-manager.:id.addons",
            title: "Addons",
            icon: "mdi-arrow-right",
            inNav: true,
            component: () =>
              import("../views/csm/company_manager/_id/addons/index.vue"),
            meta: {
              requiresAuth: true,
            },
          },

          {
            path: "mailer",
            name: "csm.company-manager.:id.mailer",
            title: "Mailer",
            icon: "mdi-arrow-right",
            inNav: true,
            component: () => import("../views/csm/company_manager/_id/mailer"),
            meta: {
              requiresAuth: true,
            },
          },

          {
            path: "contactpersons",
            name: "csm.company-manager.:id.contactpersons",
            title: "Kontaktpersonen",
            icon: "mdi-arrow-right",
            inNav: true,
            component: () =>
              import("../views/csm/company_manager/_id/contactpersons"),
            meta: {
              requiresAuth: true,
            },
          },

          {
            path: "admins",
            name: "csm.company-manager.:id.admins",
            title: "Administratoren",
            icon: "mdi-arrow-right",
            inNav: true,
            component: () => import("../views/csm/company_manager/_id/admins"),
            meta: {
              requiresAuth: true,
            },
          },

          {
            path: "legal",
            name: "csm.company-manager.:id.legal",
            title: "Rechtliches",
            icon: "mdi-arrow-right",
            inNav: true,
            component: () =>
              import("../views/csm/company_manager/_id/legal/index.vue"),
            meta: {
              requiresAuth: true,
            },
          },

          {
            path: "apis",
            name: "csm.company-manager.:id.apis",
            title: "APIs",
            icon: "mdi-arrow-right",
            inNav: true,
            component: () =>
              import("../views/csm/company_manager/_id/apis/index.vue"),
            meta: {
              requiresAuth: true,
            },
          },
          {
            path: "design",
            name: "csm.company-manager.:id.design",
            title: "Design",
            icon: "mdi-arrow-right",
            inNav: true,
            component: () =>
              import("../views/csm/company_manager/_id/design/index.vue"),
            meta: {
              requiresAuth: true,
            },
          },
          {
            path: "notes",
            name: "csm.company-manager.:id.notes",
            title: "Notizen",
            icon: "mdi-arrow-right",
            inNav: true,
            component: () =>
              import("../views/csm/company_manager/_id/notes/CompanyNotes.vue"),
            meta: {
              requiresAuth: true,
            },
          },
          {
            path: "events",
            name: "csm.company-manager.:id.events",
            title: "Events",
            icon: "mdi-arrow-right",
            inNav: true,
            component: () =>
              import(
                "../views/csm/company_manager/_id/event_calendar/CompanyEvents.vue"
              ),
            meta: {
              requiresAuth: true,
            },
          },
        ],
      },

      {
        path: "/csm/annual-marketing-plans",
        name: "csm.annual-marketing-plans",
        title: "Jahresmarketing Flex Pläne",
        icon: "mdi-newspaper-variant",
        inNav: true,
        hideChildren: true,
        component: () =>
          import(
            "../views/csm/annual_marketing_plans/AnnualMarketingPlans.vue"
          ),
        meta: {
          requiresAuth: true,
        },
      },

      {
        path: "/csm/admins",
        name: "csm.admins",
        title: "Admins",
        icon: "mdi-account-hard-hat",
        inNav: true,
        hideChildren: true,
        component: () => import("../views/csm/admins/Admins.vue"),
        meta: {
          requiresAuth: true,
        },
      },
    ],
  },

  {
    path: "/settings",
    name: "Einstellungen",
    title: "Einstellungen",
    icon: "mdi-cog",
    inNav: true,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/settings/index.vue"),
    meta: {
      requiresAuth: true,
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

/* Check, if Routes has meta requiresAuth 
if yes, check if auth
*/

/* router.beforeEach((to, from, next) => {
  let authenticated = store.getters['auth/authenticated'];
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (authenticated) {
      next();
      return;
    }
    console.log("Not AUTH");
    next("/login");
  } else {
    next();
  }
}); */

export default router;
