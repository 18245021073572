import store from "@/store"; // needed for the token
import {
  mapActions
} from "vuex"; // import actions from vuex (eg. store/modules/auth.js)
import axios from 'axios'

export default {

  methods: {

    ...mapActions({ // spread actions in to component methods (LogIn is a method in auth.js actions)
      LogOut: 'auth/LogOut'
    }),

    getRequest(route) {

      let that = this;
      return new Promise(resolve => {
        axios.get(route, {
            headers: {
              'Token': store.getters['auth/token']
            }
          })
          .then(function (response) {
            // handle success
            resolve(response);
          })
          .catch(function (error) {

            // handle error

            let errorMessage = 'Die API meldet einen Fehler!';
            let errorDetails = '<br/>' + error + ' | Route: ' + route + '. Bitte kontaktiere den SiM-Support.';

            if (error.response.status === 401) {
              errorMessage = 'Deine Sitzung ist abgelaufen!';
              errorDetails = '<br/>Bitte logge Dich erneut ein.';
              that.LogOut()
            }

            /* start snackbar */
            that.$root.snackbarGlobal.snackbar = true;
            that.$root.snackbarGlobal.snackbarText = errorMessage;
            that.$root.snackbarGlobal.errorMessage = errorDetails;
            that.$root.snackbarGlobal.snackbarColor = 'red'
            that.$root.snackbarGlobal.snackbarTimeout = 10000

            resolve(error.response.status);

          })
      })
    },

    postRequest(route, body, data) {

      if (!body) {
        body = '';
      }

      if (!data) {
        data = ''; 
      }

      let that = this;
      return new Promise(resolve => {

        axios.post(route, data, {
            headers: {
              'Token': store.getters['auth/token']
            }
          })
          .then(function (response) {
            // handle success
            resolve(response);
          })
          .catch(function (error) {

            let errorMessage = 'Die API meldet einen Fehler!';
            let errorDetails = '<br/>' + error + ' | Route: ' + route + '. Bitte kontaktiere den SiM-Support.';

            if (error && error.response && error.response.status === 401) {
              errorMessage = 'Deine Sitzung ist abgelaufen!';
              errorDetails = '<br/>Bitte logge Dich erneut ein.';
              that.LogOut()
            }

            /* start snackbar */
            that.$root.snackbarGlobal.snackbar = true;
            that.$root.snackbarGlobal.snackbarText = errorMessage;
            that.$root.snackbarGlobal.errorMessage = errorDetails;
            that.$root.snackbarGlobal.snackbarColor = 'red'
            that.$root.snackbarGlobal.snackbarTimeout = 10000

            resolve(error.response.status);

          })
      })
    },

    putRequest(route, params) {

      if (!params) {
        params = '';
      }

      let that = this;
      return new Promise(resolve => {

        axios.put(route, params, {
            headers: {
              'Token': store.getters['auth/token'],
              'Content-Type': 'application/x-www-form-urlencoded'
            }
          })
          .then(function (response) {
            // handle success
            resolve(response);
          })
          .catch(function (error) {

            let errorMessage = 'Die API meldet einen Fehler!';
            let errorDetails = '<br/>' + error + ' | Route: ' + route + '. Bitte kontaktiere den SiM-Support.';

            if (error && error.response && error.response.status === 401) {
              errorMessage = 'Deine Sitzung ist abgelaufen!';
              errorDetails = '<br/>Bitte logge Dich erneut ein.';
              that.LogOut()
            }

            /* start snackbar */
            that.$root.snackbarGlobal.snackbar = true;
            that.$root.snackbarGlobal.snackbarText = errorMessage;
            that.$root.snackbarGlobal.errorMessage = errorDetails;
            that.$root.snackbarGlobal.snackbarColor = 'red'
            that.$root.snackbarGlobal.snackbarTimeout = 10000

            resolve(error.response.status);

          })
      })
    },




    patchRequest(route, params, customContentType) {

      // ! customContentType is optional. If json needed, use "json"

      let contentType = 'application/x-www-form-urlencoded';
      if (customContentType === 'json') {
        contentType = 'application/'+customContentType;
      }

      let that = this;
      return new Promise(resolve => {

        axios.patch(route, params, {
            headers: {
              'Token': store.getters['auth/token'],
              'Content-Type': contentType
            }
          })
          .then(function (response) {
            // handle success
            resolve(response);
          })
          .catch(function (error) {

            let errorMessage = 'Die API meldet einen Fehler!';
            let errorDetails = '<br/>' + error + ' | Route: ' + route + '. Bitte kontaktiere den SiM-Support.';

            if (error && error.response && error.response.status === 401) {
              errorMessage = 'Deine Sitzung ist abgelaufen!';
              errorDetails = '<br/>Bitte logge Dich erneut ein.';
              that.LogOut()
            }

            /* start snackbar */
            that.$root.snackbarGlobal.snackbar = true;
            that.$root.snackbarGlobal.snackbarText = errorMessage;
            that.$root.snackbarGlobal.errorMessage = errorDetails;
            that.$root.snackbarGlobal.snackbarColor = 'red'
            that.$root.snackbarGlobal.snackbarTimeout = 10000

            resolve(error.response.status);

          })
      })
    },




    deleteRequest(route) {

      let that = this;
      return new Promise(resolve => {

        axios.delete(route, {
            headers: {
              'Token': store.getters['auth/token']
            }
          })
          .then(function (response) {
            // handle success
            resolve(response);

          })
          .catch(function (error) {


            if (error.response.status !== 400 && error.response.status !== 403) { // dont show error @ forbidden

              let errorMessage = 'Die API meldet einen Fehler!';
              let errorDetails = '<br/>' + error + ' | Route: ' + route + '. Bitte kontaktiere den SiM-Support.';

              if (error && error.response && error.response.status === 401) {
                errorMessage = 'Deine Sitzung ist abgelaufen!';
                errorDetails = '<br/>Bitte logge Dich erneut ein.';
                that.LogOut()
              }

              /* start snackbar */
              that.$root.snackbarGlobal.snackbar = true;
              that.$root.snackbarGlobal.snackbarText = errorMessage;
              that.$root.snackbarGlobal.errorMessage = errorDetails;
              that.$root.snackbarGlobal.snackbarColor = 'red'
              that.$root.snackbarGlobal.snackbarTimeout = 10000

              resolve(error.response.status);

            } else {

              resolve(error.response.status);

            }

          })
      })
    },





  }
};